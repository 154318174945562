<template>
    <!--//  RPD_hit_n_run_2019 DB APP v0.0.1
    //  Copyright(c) 2019 Blue Cover Six LLC / John Molt
    // BCS grants Unlimited License to Reno Police Department / City of Reno  by contract.-->
    <div id="envelope">
        <v-btn color="secondary" id="envelope-print-button" @click="print">
            PRINT ENVELOPE
        </v-btn>
        <div id="envelope-print-form">
            <div class="box">
                <div class="letter-pg1">
                    <h6>{{envelope_data.sus_name}}</h6>
                    <h6>{{envelope_data.sus_address}}</h6>
                    <h6>{{envelope_data.sus_city}},&nbsp;{{envelope_data.sus_state}},&nbsp;{{envelope_data.sus_zip}}</h6>
                </div>
            </div>
        </div>
    </div>

</template>


<style lang="scss" scoped>
    #envelope-print-form {
        display: none;
    }

    #envelope-print-button {
        display: none;
    }
</style>

<script>
    /* eslint-disable */
    import { bus_common } from '../mixins/bus_common'
    import print from 'print-js'
    export default {
        name: 'envelope',
        mixins: [bus_common],
        props: ["envelope_data"],
        data() {
            return {
            }
        },

        methods: {
            print: function () {
                this.$htmlToPaper('envelope-print-form');
            },
            printjs: function () {
                /* this.addHashToLocation('/my/new/path')*/
                printJS({
                    printable: "envelope-print-form",
                    documentTitle: "Envelope",
                    type: "html",
                    scanStyles: false,
                    css: './env_print.css',
                    onLoadingEnd: () => {
                        //setTimeout(() => {
                        //    history.back()
                        //}, 500);
                    }
                });


            },
            addHashToLocation(params) {
                history.pushState(
                    {},
                    null,
                    "/"
                )
            },
            GenImageLink(filename) {
                let link = window.location.origin + "/Upload/" + filename;
                return link
            },
            async init() {

            },
        },

        mounted() {
        },
        beforeDestroy() {
        },

    }
</script>
