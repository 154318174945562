<template>
    <v-container id="printables-container">

        <victim_letter_print :vic_ltr_data="vic_ltr_data" ref="victim_letter"></victim_letter_print>
        <suspect_letter_print :sus_ltr_data="sus_ltr_data" ref="suspect_letter"></suspect_letter_print>
        <envelope_print :envelope_data="envelope_data" ref="envelope"></envelope_print>
    </v-container>
</template>

<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import victim_letter_print from "./victim_letter_print";
    import suspect_letter_print from "./suspect_letter_print";
    import envelope_print from "./envelope_print";
    export default {
        name: "printables",
        mixins: [bus_common],
        props: ["vic_ltr_data", "sus_ltr_data", "envelope_data"],
        components: {
            victim_letter_print, suspect_letter_print, envelope_print
        },
        data() {
            return {};
        },
        methods: {
            message1() {
                alert("This button does nothing yet.");
            },
            init() { },
            printVictimLetter() {
                this.$refs.victim_letter.printjs()
            },
            printSuspectLetter() {
                this.$refs.suspect_letter.printjs()
            },
            printEnvelope() {
                this.$refs.envelope.printjs()
            }

        },
        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },
    };
</script>
