import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
    mdiEye, mdiEyeOff, mdiCheck, mdiCheckDecagram, mdiCheckDecagramOutline, mdiCancel, mdiClose, mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiCloseCircle, mdiMenuDown, mdiMenu,
    mdiArrowLeft, mdiArrowRight, mdiSend, mdiCheckboxMarkedCircleOutline, mdiMagnify, mdiViewDashboardOutline, mdiAccountEditOutline, mdiLogout, mdiAccount, mdiInformationOutline,
    mdiTextBoxSearch, mdiTextBoxSearchOutline, mdiExitToApp, mdiClipboardOutline, mdiAccountBox, mdiHome, mdiAccountCircle, mdiChevronUp,
    mdiChevronDown, mdiPlay, mdiRestore, mdiStop, mdiContentSave, mdiCloseCircleOutline, mdiPencil, mdiDelete, mdiCheckboxMarkedCircle, mdiPlus, mdiChevronRight, mdiChevronLeft,
    mdiArrowUp, mdiStar, mdiStarOutline, mdiFileDocument, mdiEmail, mdiImagePlus
} from '@mdi/js'

//Usage '$vuetify.icons.xbox' or $xbox
const MY_ICONS = {
    // jm added
    plus: mdiPlus,
    cancel1: mdiCloseCircleOutline,
    save: mdiContentSave,
    stop: mdiStop,
    loop: mdiRestore,
    play: mdiPlay,
    chevup: mdiChevronUp,
    chevdown: mdiChevronDown,
    person: mdiAccountCircle,
    home: mdiHome,
    checkedcircle: mdiCheckboxMarkedCircle,
    /*Default List*/
    eye: mdiEye,
    eyeoff: mdiEyeOff,
    complete: mdiCheck,
    cancel: mdiCancel,
    close: mdiClose,
    delete: mdiCloseCircle, // delete (e.g. v-chip close)
    del: mdiDelete,
    clear: mdiClose,
    success: mdiCheck,
    successOutline: mdiCheckDecagramOutline,
    successBold: mdiCheckDecagram,
    info: mdiInformationOutline,
    warning: '...',
    error: '...',
    prev: mdiChevronLeft,
    next: mdiChevronRight,
    checkboxOn: mdiCheckboxMarked,
    checkboxOff: mdiCheckboxBlankOutline,
    checkboxIndeterminate: '...',
    delimiter: '...', // for carousel
    sort: mdiArrowUp,
    expand: mdiMenuDown,
    menu: mdiMenu,
    subgroup: '...',
    dropdown: mdiMenuDown,
    radioOn: '...',
    radioOff: '...',
    edit: mdiPencil,
    ratingEmpty: '...',
    ratingFull: '...',
    ratingHalf: '...',
    loading: '...',
    first: '...',
    last: '...',
    unfold: '...',
    file: '...',
    /*Additional Icons*/
    arrowLeft: mdiArrowLeft,
    arrowRight: mdiArrowRight,
    send: mdiSend,
    checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
    magnify: mdiMagnify,
    search: mdiMagnify,
    appViewDash: mdiViewDashboardOutline,
    accountEdit: mdiAccountEditOutline,
    logout: mdiLogout,
    account: mdiAccount,
    textSearch: mdiTextBoxSearch,
    textSearchOutline: mdiTextBoxSearchOutline,
    exitToApp: mdiExitToApp,
    clipboard: mdiClipboardOutline,
    email: mdiEmail,
    fileDocument: mdiFileDocument,
    pencil: mdiPencil,
    starOutline: mdiStarOutline,
    star: mdiStar,
    imagePlus: mdiImagePlus

}


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: MY_ICONS,
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                background: '#0D1117',
                primary: '#FFFFFF',
                secondary: '#22F1BF',
                accent: '#7371FF',
                error: '#f44336',
                warning: '#ff9800',
                info: '#03a9f4',
                success: '#4caf50',
                backdrop: '#21262D',
                //background-color: var(--v-accent-lighten2)
            },
        },
        options: {
            customProperties: true
        },
    },
});
