<template>
  <!--//  RPD_hit_n_run_2019 DB APP v0.0.1
  //  Copyright(c) 2019 Blue Cover Six LLC / John Molt
  // BCS grants Unlimited License to Reno Police Department / City of Reno  by contract.-->
    <div id="suspect_letter">
        <v-btn color="secondary" id="suspect-print-button" @click="print">
            PRINT SUSPECT LETTER
        </v-btn>
        <div id="suspect-print-form">
            <div class="box">
                <div class="letter-pg1">
                    <div class="letter-header">
                        <div class="lh-flex"> Reno Police Department<br><em>"Your Police, Our Community"</em></div>
                        <div><img src="../images/gold-badge.png" alt="Reno nevada police Badge" /></div>
                        <div class="lh-flex-3">Kathryn Nance<br /> <em>Chief of Police</em></div>
                    </div>
                    <div class="lh-title"><h4>Traffic Crash Supplement</h4></div>
                    <br />
                    <div class="instructions">
                        <h4 class="bold">RPD Case Number: <span style="font-weight:800;color:black;"> {{sus_ltr_data.case_num}}</span></h4>
                        <h4 class=" bold italic">Instructions:</h4>
                        <h4 class=" bold italic">Complete the blue highlighted portions of this supplemental Traffic Crash Report form and return with a completed statement form.</h4>
                    </div>
                    <div class="form blue bold normal">
                        <div class="info-row ">
                            <div class="label1-5"><h6>Driver's Name</h6></div>
                            <div class="line3-5"></div>
                            <div class="label1-5"><h6>Date of Birth</h6></div>
                            <div class="line75"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Driver's Address</h6></div>
                            <div class="line5-87"></div>
                        </div>
                        <div class="info-row">
                            <div class="label1-5"><h6>City</h6></div>
                            <div class="line3-5"></div>
                            <div><h6>State</h6></div>
                            <div class="line75"></div>
                            <div><h6>Zip</h6></div>
                            <div class="line75"></div>
                        </div>
                        <div class="info-row">
                            <div class="label1-5 move-rt"><h6>Driver's Phone #</h6></div>
                            <div><h6>Home</h6></div>
                            <div class="line1-25"></div>
                            <div><h6>Cell</h6></div>
                            <div class="line1-25"></div>
                            <div><h6>Work</h6></div>
                            <div class="line1-25"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Driver's License #</h6></div>
                            <div class="line3-5"></div>
                            <div class="label1-5"><h6>D/L State</h6></div>
                            <div class="line75"></div>
                        </div>
                        <div class="info-row">
                            <div class="label1-5"><h6>Vehicle Year</h6></div>
                            <div class="line3-5"></div>
                            <div><h6>Make</h6></div>
                            <div class="line75"></div>
                            <div><h6>Model</h6></div>
                            <div class="line75"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Vehicle License Plate #</h6></div>
                            <div class="line3-5 wraptext"></div>
                            <div class="label1-5 wraptext"><h6>Lic Plate State</h6></div>
                            <div class="line75 wraptext"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Registered Owner Name(s)</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Registered Owner's Address</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                        <div class="info-row">
                            <div class="label1-5"><h6>City</h6></div>
                            <div class="line3-5"></div>
                            <div><h6>State</h6></div>
                            <div class="line75"></div>
                            <div><h6>Zip</h6></div>
                            <div class="line75"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Insurance Company Name</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Insurance Policy #</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Agent Name</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                        <div class="info-row ">
                            <div class="label1-5"><h6>Insurance Claims Phone #</h6></div>
                            <div class="line5-87 wraptext"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</template>


<style lang="scss" scoped>
    #suspect-print-form {
        display: none;
    }

    #suspect-print-button {
        display: none;
    }

    .letter_badge {
        max-width: 125px;
        max-height: 125px;
    }

    .letter-header {
        display: inline-flex;
    }

    </style>

<script>
    /* eslint-disable */
    import { bus_common } from '../mixins/bus_common'
    import print from 'print-js'
    export default {
        name: 'suspect_letter',
        mixins: [bus_common],
        props: ["sus_ltr_data"],
        data() {
            return {
                showltr: true,

            }
        },

        methods: {
            print: function () {
                this.$htmlToPaper('suspect-print-form');
            },
            printjs: function () {
                /* this.addHashToLocation('/my/new/path')*/
                printJS({
                    printable: "suspect-print-form",
                    documentTitle: "Suspect Letter",
                    type: "html",
                    scanStyles: false,
                    css: './sus_print.css',
                    onLoadingEnd: () => {
                        //setTimeout(() => {
                        //    history.back()
                        //}, 500);
                    }
                });


            },
            addHashToLocation(params) {
                history.pushState(
                    {},
                    null,
                    "/"
                )
            },
            GenImageLink(filename) {
                let link = window.location.origin + "/Upload/" + filename;
                return link
            },
            async init() {

            },
        },

        mounted() {
        },
        beforeDestroy() {
        },

    }
</script>
